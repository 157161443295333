import LayoutPageBlank from '@/components/templateux/layout/layout-page-blank'
import { meta } from '@/lib/config';
import React from "react";
import LoadingInner from "./loading-inner";

export default function Loading ({
    message=<>Loading Page</>
  , showRandom=false
  , classSize='w-32 h-32 sm:w-48 sm:h-48'
  ,classAnimate='animate-spin-slow'
  ,bgColor='#00000000'
  ,centerColor='#00000000'
  ,pointerColor='#c0c0c0'
  ,holeColor='#00000000'
  ,dialerColor='#c0c0c0aa'
  ,size='64'

}) {

  return (
    <>
      <div className="flex  ">
          <div className="m-auto">
            <h3 className="text-lg sm:text-4xl">
            <LoadingInner
              showRandom={showRandom}
              message={message}
              classSize={classSize}
              classAnimate={classAnimate}
              bgColor={bgColor}
              centerColor={centerColor}
              pointerColor={pointerColor}
              holeColor={holeColor}
              dialerColor={dialerColor}
              size={size}
            />
        </h3>
      </div>
    </div>
    </>)

  
}