import { useState } from "react";
import ChatDraw from "../ably/ChatDraw";
import { allowImages } from "@/lib/utils/allowImages";
import { CHAT_IMAGE_PERMS } from "@/lib/config";

export default function Dalle({
    router,
    sendmsg,
    closeModal,
    bol_private,
    channel_id,
    canvas,
    set_canvas,
    profiledata,
    changes,
    set_changes
}) {
    const [prompt, setPrompt] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [loading, setLoading] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(1);
        const response = await fetch('/api/playground/dalle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prompt
            })
        });
        const imageResponse = await response.json();
        // setImageURL(imageResponse.imageURL)
        //console.log(imageResponse);
        setImageURL(imageResponse.imageURL);
        set_canvas(prev=>{return {...prev,src:imageResponse.imageURL}})
        setLoading(0);
    }

    //check to see if this is enabled, otherwise return an error message
    if (!allowImages(profiledata?.bol_chat_image,
        profiledata?.bol_chat_image_bitarray,
        "ai",
        CHAT_IMAGE_PERMS) ) {
        return (<>Sorry! Chat AI photos have been disabled by {profiledata?.user_name}.</>)
    }

    if (loading) {
        return <Loading></Loading>
    }

    if (imageURL !== '' && loading === 0) {
        return (<> 
            {/* <div className="imageContainer">
                <img src={imageURL}></img>
            </div> */}
            {/* <ChatDraw
                router={router}
                sendmsg={sendmsg}
                closeModal={closeModal}
                bol_private={bol_private}
                channel_id={channel_id}
                canvas={canvas}
                set_canvas={set_canvas}
                bol_tools={false}
                profiledata={profiledata}
                changes={changes}
                set_changes={set_changes}
            /> */}
        </>)
    }

    return (
        <div>
            <div className="search-box">
                <form onSubmit={handleSubmit}>
                    <button className="btn-search"><i className="fa fa-search"></i></button>
                    <input type="text" id="prompt" name="prompt" className="w-full p-1 text-black bg-white" onChange={(e) => setPrompt(e.target.value)} placeholder="Generate Image with AI ..."></input>
                </form>
            </div>
        </div>
    )
}

function Loading(){
	return <div>Loading...</div>
}