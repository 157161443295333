import { config } from "@/lib/config"
import GatingMessageModal from "./message-modal"
import { TribeContext } from "@/components/context/tribe-wrapper"
import { useContext } from "react"

export default function GatingContributor({
    show_condition,
    gated_message=config.gate.defaultmessage,
    message=<><GatingMessageModal 
                message={gated_message}
    /></>,
    gate_height=config.gate.windowheight,
    children
}) {
  
  const ssr_data_tribe = useContext(TribeContext)

    if (ssr_data_tribe?.bol_contribute?.toString() == "1" && !show_condition) return (<>
    <div 
        className="relative"
        style={{height: gate_height}}
    >
        <div 
            className="z-1 absolute overflow-hidden w-full p-4"
            style={{height: gate_height}}
        >
            {children}
        </div>
        
        <div 
            className="absolute z-100  w-full bg-gradient-to-t  from-gray-800 to-transparent"
            style={{height: gate_height}}
        >
            
        </div>   
        <div 
            className="absolute z-100  w-full"
            style={{height: gate_height}}
        >
            {message}
        </div>   
    </div>
    
    </>)

    return (<>{children}</>)
}