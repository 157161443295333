import { config } from "@/lib/config";
import Link from "next/link";
import RotaryLogo from "../rotary-logo";
import SvgJsx from "../svg/svg-jsx";

export default function GatingMessageModal({
  gate_height=config.gate.windowheight,
  message=config.gate.defaultmessage
}) {
    return <>
    <Link href='/contribute' target='_blank' rel="noopener noreferrer">

      <div className={`flex items-center content-center  justify-center group cursor-pointer`}
        style={{background: "rgb(0,0,0,0.6", height: gate_height}}
      >
      
              <div className="  shadow-xl text-center  bg-gray-800 border-4  border-gray-700 mt-5 p-5 rounded-xl group-hover:bg-gray-700  group-hover:border-white ">
                  <div className="text-center w-full mx-auto">
                      {/* <SvgJsx 
                          type={'fill'}
                          icon={"lock-closed-sm"}
                          className={`h-20 w-20 mx-auto text-red-600`}
                          title={"Access is Restricted"}
                      /> */}
                      <div className={` text-3xs px-0.5 py-0.5 rounded-full cursor-pointer group`}>
                      
                      {/* <SvgJsx 
                        type='outline'
                        icon="check"
                        className={`${classSize == 'big'
                                ? 'h-3 w-3'
                                : 'h-1.5 w-1.5'}
                                   text-white`}
                        title={flare}
                      /> */}

                    <div className={`flex-0 justify-left text-sm  `}>
                      <div className="flex-0  mr-1 ">
                      
                        <RotaryLogo 
                          classSize={`h-10 w-10 sm:h-20 sm:w-20 mx-auto block`}
                          classAnimate='animate-spin'
                          bgColor='transparent'
                          centerColor='rgba(31,41,55,1)'
                          pointerColor='#ff0000'
                          holeColor='rgba(31,41,55,1)'
                          dialerColor='#ffffff'
                          size='64'
                        />
                      </div>
                    </div>

                  </div>
                  </div>
                  <div className='font-bold text-lg sm:text-2xl'>
                  Contributor-only feature! 
                  </div>
                  <div className="mt-2 text-sm sm:text-base">
                  
                      
                      {message}
                    
                  </div>
                  <div className="mt-2 flex justify-center">



                  <div 
                    className={` mt-2
                    border rounded-full  border-gray-200  group-hover:border-white group-hover:shadow-md
                              
                                text-lg font-bold
                                `} >
                  <div
                
                      className={`px-4 py-2 my-auto flex items-center content-center
                          
                          `}>


                    <div className="flex-0 mr-2 ">
                        <div className="relative h-5 w-5">
                          <div className="absolute">
                          <SvgJsx 
                                type={'fill'}
                                      
                                icon={'heart-sm'}
                                className={`group-hover:animate-ping h-5 w-5 text-red-600 group-hover:text-red-500`}
                                title={'Contribute'}
                            />
                          </div>
                          <div className="absolute">
                            <SvgJsx 
                                type={'fill'}
                                      
                                icon={'heart-sm'}
                                className={`absolute h-5 w-5 text-red-600 group-hover:text-red-500`}
                                title={'Contribute'}
                            />
                          </div>
                        </div>
                    </div>



                            <div className="flex-0">
                              Contribute
                            </div>
                        
                      
                      </div>
                    </div>


                  </div>
              </div>
          
      </div>

    </Link>
    </>;
}