import { useEffect, useRef } from "react";

export default function useOnDraw(onDraw,onPreview) {

    const canvasRef = useRef(null);
    const previewRef = useRef(null);
    const isDrawingRef = useRef(false);
    const prevPointRef = useRef(null);

    const mouseDownListenerRef = useRef(null);
    const mouseMoveListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);
    const touchStartListenerRef = useRef(null);
    const touchMoveListenerRef = useRef(null);
    const touchEndListenerRef = useRef(null);

    function setCanvasRef(ref) {
        canvasRef.current = ref;
    }

    function setPreviewRef(ref) {
        previewRef.current = ref;
    }

    function onCanvasMouseDown() {
        isDrawingRef.current = true;
    }

    useEffect(() => {
        function computePointInCanvas(clientX, clientY) {
            if (canvasRef.current) {
                const boundingRect = canvasRef.current.getBoundingClientRect();
                return {
                    x: clientX - boundingRect.left,
                    y: clientY - boundingRect.top
                }
            } else {
                return null;
            }

        }



        function initMouseDownListener() {
            const mouseDownListener = (e) => {
                if (isDrawingRef.current && canvasRef.current) {
                    const point = computePointInCanvas(e.clientX, e.clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, point);
                    prevPointRef.current = point;
                    //console.log(point);
                }
            }
            mouseDownListenerRef.current = mouseDownListener;
            window.addEventListener("mousedown", mouseDownListener);
        }

        function initMouseMoveListener() {
            const mouseMoveListener = (e) => {
                if (isDrawingRef.current && canvasRef.current) {
                    const point = computePointInCanvas(e.clientX, e.clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    //console.log(point);
                }
            }
            mouseMoveListenerRef.current = mouseMoveListener;
            window.addEventListener("mousemove", mouseMoveListener);
        }

        function initTouchStartListener() {
            const touchStartListener = (e) => {
                if (isDrawingRef.current && canvasRef.current) {
                    //console.log("e",e,e.touches[0].clientX, e.touches[0].clientY)
                    const point = computePointInCanvas(e.touches[0].clientX, e.touches[0].clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    //console.log(point);
                }
            }
            touchStartListenerRef.current = touchStartListener;
            window.addEventListener("touchstart", touchStartListener);
        }

        function initTouchMoveListener() {
            const touchMoveListener = (e) => {
                if (isDrawingRef.current && canvasRef.current) {
                    //console.log("e",e,e.touches[0].clientX, e.touches[0].clientY)
                    const point = computePointInCanvas(e.touches[0].clientX, e.touches[0].clientY);
                    const ctx = canvasRef.current.getContext('2d');
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    //console.log(point);
                }
            }
            touchMoveListenerRef.current = touchMoveListener;
            window.addEventListener("touchmove", touchMoveListener);
        }

        function initMouseUpListener() {
            const listener = () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
            }
            mouseUpListenerRef.current = listener;
            window.addEventListener("mouseup", listener);
        }

        function initTouchEndListener() {
            const listener = () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
            }
            touchEndListenerRef.current = listener;
            window.addEventListener("touchend", listener);
        }

        function cleanup() {

            if (mouseMoveListenerRef.current) {
                window.removeEventListener("mousemove", mouseMoveListenerRef.current);
            }
            if (mouseDownListenerRef.current) {
                window.removeEventListener("mousedown", mouseDownListenerRef.current);
            }
            if (mouseUpListenerRef.current) {
                window.removeEventListener("mouseup", mouseUpListenerRef.current);
            }
            if (touchStartListenerRef.current) {
                window.removeEventListener("touchstart", touchStartListenerRef.current);
            }
            if (touchMoveListenerRef.current) {
                window.removeEventListener("touchmove", touchMoveListenerRef.current);
            }
            if (touchEndListenerRef.current) {
                window.removeEventListener("touchup", touchEndListenerRef.current);
            }
        }

        initMouseDownListener();
        initMouseMoveListener();
        initMouseUpListener();
        initTouchStartListener();
        initTouchMoveListener();
        initTouchEndListener();
        return () => cleanup();

    }, [onDraw]);

    return {
        canvasRef,
        setCanvasRef,
        previewRef,
        setPreviewRef,
        onCanvasMouseDown
    }

};