import { useEffect, useState } from "react";
import  useOnDraw  from "@/lib/utils/hooks/useOnDraw";
import { hasData } from "@/lib/utils/hasData";
import { getContentLength } from "@/lib/utils/getContentLength";
import { formatBytes } from "@/lib/utils/formatBytes";
import toast from "react-simple-toasts";
import { config } from "@/lib/config";
import RotaryLogo from "../templateux/rotary-logo";


//https://www.youtube.com/watch?v=9uwZkqoFAfg
//https://www.codicode.com/art/undo_and_redo_to_the_html5_canvas.aspx
//inspiration: pixelvee.netlify.app

const Canvas = ({
    canvas={
        width:"600", 
        height:"600", 
        bgcolor: config.draw.canvascolor,
        brushcolor: config.draw.brushcolor,
        title:"Untitled image",
        src:"",
        id:"0",
        size:0
      }
    ,set_canvas
    ,save_behavior='app'
    ,set_upload_file
    ,submitting
    ,set_submitting
    ,router
    ,bol_tools
    ,changes
    ,set_changes
}) => {

    // console.log("canvas",canvas)
    
    // ctx = document.getElementById('myCanvas').getContext("2d");
        
    const [show_tools,set_show_tools] = useState(true)
    const [cStep,set_cStep] = useState(-1)
    const [cPushArray,set_cPushArray] = useState([])
    const [brush,set_brush] = useState({
                                        color: canvas.brushcolor || config.draw.brushcolor,
                                        alpha: 100,
                                        radius: 4
                                        })
    const [brush_history,set_brush_history] = useState([canvas.bgcolor])
    const [is_drawing,set_is_drawing] = useState(false)
    
    const {
        canvasRef,
        setCanvasRef,
        previewRef,
        setPreviewRef,
        onCanvasMouseDown
    } = useOnDraw(onDraw,onPreview);

    //get the first history item (blank canvas)
    useEffect(()=>{
        onPreview();
        if (cPushArray.length == 0) cPush(canvasRef.current.toDataURL());
    },[])    

    useEffect(()=>{
        onPreview();
    },[brush.color,brush.alpha])    

    useEffect(()=>{
        if (hasData(canvas.src)) {
            cPush(canvas.src);
            nState(canvas.src);
            set_changes(true);
        }
    },[canvas.src])   

    useEffect(()=>{
        if (hasData(canvas.brushcolor)) {
            set_brush(prev=>{return {...prev,color: canvas.brushcolor}})
        }
    },[canvas.brushcolor])   

   async function cPush(dataurl) {
        await set_cStep(prev=>prev+1);
        await set_cPushArray(prev=> {
            let tempArray = prev;
            tempArray.push(dataurl)
            return tempArray
        })
    }

    function resetCanvas(ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        //ctx.drawImage(canvasPic, 0, 0); 
        set_changes(false);
    }

     function nState(data) {
        const ctx = canvasRef.current.getContext('2d');
        var canvasPic = new Image();
        canvasPic.src = data;
        canvasPic.crossOrigin="anonymous"
        canvasPic.onload = async function () { 
             //ctx.imageSmoothingEnabled = true;
            await ctx.clearRect(0, 0, canvas.width, canvas.height);
            await ctx.drawImage(canvasPic, 0, 0); 
        }
    }

    //https://stackoverflow.com/questions/20958078/resize-a-base-64-image-in-javascript-without-using-canvas
    const resizeBase64Image = (base64: string, width: number, height: number): Promise<string> => {
        // Create a canvas element
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
      
        // Create an image element from the base64 string
        const image = new Image();
        image.src = base64;
      
        // Return a Promise that resolves when the image has loaded
        return new Promise((resolve, reject) => {
          image.onload = () => {
            // Calculate the aspect ratio of the image
            const aspectRatio = image.width / image.height;
      
            // Calculate the best fit dimensions for the canvas
            if (width / height > aspectRatio) {
              canvas.width = height * aspectRatio;
              canvas.height = height;
            } else {
              canvas.width = width;
              canvas.height = width / aspectRatio;
            }
      
            // Draw the image to the canvas
            canvas.getContext('2d')!.drawImage(image, 0, 0, canvas.width, canvas.height);
      
            // Resolve the Promise with the resized image as a base64 string
            resolve(canvas.toDataURL());
          };
      
          image.onerror = reject;
        });
      };

    async function cUndo() {
        if (cStep > 0) {
            await set_cStep(prev=> {
                let nStep = prev-1
                nState(cPushArray[nStep])
                return nStep
            })
        }
    }

    async function cRedo() {
        if (cStep < cPushArray.length-1) {
            await set_cStep(prev=> {
                let nStep = prev+1
                nState(cPushArray[nStep])
                return nStep
            })
        }
    }

    function onDraw(ctx, point, prevPoint) {
        //drawLine(prevPoint, point, ctx, changeColorAlpha(brush.color, brush.alpha/100), (brush.radius*2)+1);
        drawSoft(ctx,point,((brush.radius*2)+1),brush.color,brush.alpha);
        set_changes(true);
    }

    function onPreview() {
        let ctx = previewRef.current.getContext('2d');
        resetCanvas(previewRef.current.getContext('2d'));
        drawSoft(ctx,{x:35,y:35},((brush.radius*2)+1),brush.color,brush.alpha)
    }

    function drawLine(
        start,
        end,
        ctx,
        color,
        width
    ) {
        start = start ?? end;
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(start.x, start.y, brush.radius, 0, brush.radius * Math.PI);
        ctx.fill();

    }


    function rgbFromPercent(percentage) {

        const rangeMin = 0;
        const rangeMax = 255;

        return Math.round((((rangeMax - rangeMin) / 100) * percentage ) + rangeMin);

    }

    function drawSoft(ctx,point,width,color,alpha){

        let rgb = hexToRgb(color);

        let innerX = point.x;
        let innerY = point.y;
        let outerX = point.x;
        let outerY = point.y;

        var gradient = ctx.createRadialGradient(innerX, innerY, 0, outerX, outerY, width);

        gradient.addColorStop(alpha/100, 'rgba('+rgb.r+', '+rgb.g+', '+rgb.b+', 255)');  
        gradient.addColorStop(1, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`);

        ctx.beginPath();
        ctx.arc(point.x, point.y, width, 0, 2 * Math.PI);
        ctx.fillStyle = gradient;
        ctx.fill();
        ctx.closePath();
    };

    function changeColorAlpha(color, opacity) {
        //if it has an alpha, remove it
        if (color.length > 7)
            color = color.substring(0, color.length - 2);

        // coerce values so ti is between 0 and 1.
        const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
        let opacityHex = _opacity.toString(16).toUpperCase()

        // opacities near 0 need a trailing 0
        if (opacityHex.length == 1)
            opacityHex = "0" + opacityHex;

        return color + opacityHex;
    }

    function rgbToHex(r, g, b) {
        return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
    }

    function hexToRgb(hex) {

        if (hex.length > 7) hex = hex.substring(0, hex.length - 2);

        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });
      
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

    async function submitHandler(e) {

        e.preventDefault();
        set_submitting(true);

        try {
          
          //
          if (cStep >= 0 && getContentLength(cPushArray[cStep]) > 0) {

            //merge the background

            let draw_buffer = await canvasRef.current.toDataURL() // cPushArray[cStep]

            const res = await fetch(`/api/private/upload/draw${(parseInt(canvas.id) > 0) ? `?id=${canvas.id}` : ''}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                 filename: canvas.title
                ,width: canvas.width
                ,height: canvas.height
                ,title: canvas.title
                ,bgcolor: canvas.bgcolor
                ,size: getContentLength(draw_buffer)
                ,source: 'client'
                ,buffer: draw_buffer
                ,buffer_sq: await resizeBase64Image(draw_buffer, 100, 100)
                }),
            })
    
            const json = await res.json()
            if (json) {
                //console.log("json",json)
              const { outcome,file_details } = json
              //console.log(outcome,file_details)
              toast(`Your drawing was saved. Nice work!${save_behavior == "app" ? " You can find it in your uploads gallery in chat." : ''}`, { time: 3000, className: '', clickable: true, clickClosable: false });
              set_upload_file(JSON.stringify([file_details]));
              set_canvas(prev=>{return {...prev,
                                        id:file_details?.upload_id?.toString(),
                                        size:file_details?.size
                                    }})
              set_changes(false);
              if (save_behavior == "app" && canvas.id?.toString() !== file_details?.upload_id?.toString()) {
                router.push({
                    pathname: `${config.draw.app_base_url}`,
                    query: { id: file_details?.upload_id?.toString()}
                  }, 
                  undefined, { shallow: true }
                )
              }
            }
    
          }
          
        } catch (e) {
         throw Error(e.message)
        }
      }

    return(<>
        <div className="flex text-2xs">

            {/* CANVAS className=" cursor-crosshair"  */}
            <div>
                    
                    <div  className=" cursor-crosshair" > 
                    <canvas
                        width={canvas.width}
                        height={canvas.height}
                        onMouseDown={()=> {
                            set_is_drawing(true);
                            onCanvasMouseDown();
                        }}
                        onTouchStart={()=> {
                            set_is_drawing(true);
                            onCanvasMouseDown();
                        }}
                        onTouchEnd={()=> {
                            
                            if (is_drawing) {
                                // set_brush_history(prev=> {
                                //     let new_history = prev;
                                //     if (new_history.indexOf(brush.color) <= -1) new_history.push(brush.color);
                                //     return new_history;
                                // })
                                cPush(canvasRef.current.toDataURL());
                            }
                            set_is_drawing(false);
                        }}
                        onMouseUp={()=> {
                            
                            if (is_drawing) {
                                set_brush_history(prev=> {
                                    let new_history = brush_history;
                                    if (new_history.indexOf(brush.color) <= -1) new_history.push(brush.color);
                                    return new_history;
                                })
                                cPush(canvasRef.current.toDataURL());
                            }
                            set_is_drawing(false);
                        }}
                        className={`border `}
                        //style={{background:canvas.bgcolor}}
                        style={{background: `url('/images/app/transparent-grid.png') repeat`}}
                        ref={setCanvasRef}
                    />
                </div>
                {save_behavior=="local" && 
                <div
                    className={`${show_tools ? 'mt-4' : ''} flex justify-center`}
                >
                    <div
                    onClick={(e)=>submitHandler(e)}
                        className="text-white text-xl shadow-sm bg-red-600 hover:bg-red-500 cursor-pointer font-bold rounded-full text-center px-4 py-2"
                    >
                    {!submitting 
                    ? 'Save and Post' 
                    : <>
                        <div className={`flex-0 justify-left text-sm flex items-center content-center`}>
                            <div className="flex-0  mr-1 animate-spin">
                            
                            <RotaryLogo 
                                classSize='w-4 h-4 mx-auto block'
                                classAnimate=' animate-spin-slow '
                                bgColor='transparent'
                                centerColor='rgba(31,41,55,1)'
                                pointerColor='#ff0000'
                                holeColor='#111827'
                                dialerColor='#ffffff'
                                size='64'
                            />
                            </div>
                            <div className="ml-2 font-bold">
                                Posting...
                            </div>
                        </div>
                        </>
                    }
                    </div>
                    
                </div>
                }
            </div>
            


            <div className="ml-4 text-2xs ">
                <div className={`fixed ${save_behavior == "local" ? "top-20" : "top-0" }   right-0  bg-gray-900 rounded-md border border-gray-700 shadow-md ${show_tools ? 'p-4' : ''}`}>

                    <div
                        onClick={()=>set_show_tools(prev=>!prev)}
                        className={` cursor-pointer font-bold rounded-md border text-center border-gray-700 hover:border-white px-2 py-0.5`}
                    >
                        {show_tools ? 'hide' : 'tools'}
                    </div>

                    {/* TOOLS */}
                    {show_tools &&
                    <div className="mt-4 ">

                        

                        <div className="flex items-center content-center text-2xs ">
                            <button
                                onClick={()=>cUndo()}
                                className={`rounded-md border border-gray-700  hover:border-white px-1 py-0.5`}
                            >
                                undo
                            </button>
                            <button
                                onClick={()=>cRedo()}
                                className={`ml-1 rounded-md border border-gray-700 hover:border-white  px-1 py-0.5`}
                            >
                                redo
                            </button>
                        </div>

                        <div className="flex flex-col items-top content-start">

                            <div className=''>
                                {/* PREVIEW */}
                                <label htmlFor="color">
                                    <canvas
                                        width={70}
                                        height={70}
                                        
                                        className={`border rounded-md `}
                                        style={{background: `url('/images/app/transparent-grid.png') repeat`}}
                                        ref={setPreviewRef}
                                    />
                                </label>

                            </div>

                            <div className=" mt-2 w-[70px]">

                                {/* SIZE */}
                                <div className="flex-0 text-2xs">
                                    <div className="flex items-center content-center">
                                        <div className="flex-1">
                                            Size 
                                        </div>
                                        <div className="opacity-60">
                                        {brush.radius}
                                        
                                        </div>
                                    </div>
                                    <div className="flex items-center content-center">
                                        <input 
                                            type="range" 
                                            min="0" 
                                            max="15" 
                                            value={brush.radius}
                                            onChange={(e)=>{
                                                set_brush({...brush,radius:(parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0)});
                                                onPreview();
                                            }}
                                            className='w-full bg-gray-400 h-4  sm:h-2 rounded-md mt-1'
                                        />
                                        
                                    </div>
                                </div>

                                {/* OPACITY */}
                                <div className="flex-0 mt-2 text-2xs">
                                    <div className="flex items-center content-center">
                                        <div className="flex-1 s">
                                           Hardness
                                        </div>
                                        <div className="opacity-60">
                                        {brush.alpha}%
                                        </div>
                                    </div>
                                    <div className="flex items-center content-center">
                                        <input 
                                            type="range" 
                                            min="1" 
                                            max="100"
                                            value={brush.alpha}
                                            onChange={(e)=>{
                                                set_brush({...brush,alpha:(parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0)});
                                                onPreview();
                                            }}
                                            className='w-full bg-gray-400 h-4  sm:h-2 rounded-md mt-1'
                                        />
                                        
                                    </div>
                                </div>

                                {/* COLOR */}
                                <div className="flex items-center content-center mt-3 w-full">
                                    <div className=" flex-1 text-center font-bold text-2xs">
                                        Brush
                                        <div className="flex flex-col items-center content-center ">
                                            
                                            <div>
                                            <input 
                                                type="color"
                                                id="color"
                                                className="text-black  h-8 w-8  p-0"
                                                value={brush.color}
                                                onChange={(e)=>set_brush({...brush,color:(`${e.target.value}`)})}
                                                onBlur={()=>{
                                                    set_brush_history(prev=> {
                                                        let new_history = prev;
                                                        if (new_history.indexOf(brush.color) <= -1) new_history.push(brush.color);
                                                        return new_history;
                                                    })
                                                }}
                                            />
                                            </div>
                                            <div className="opacity-60 text-3xs">
                                            {brush.color?.replace('#','')}
                                            </div>
                                        </div>  
                                    </div>
                                    {/* <div className=" flex-0 text-center font-bold text-2xs">
                                        BG
                                        <div className="flex flex-col items-center content-center ">
                                            
                                            <div>
                                            <input 
                                                type="color"
                                                id="bgcolor"
                                                className="text-black h-8 w-8  p-0"
                                                value={canvas.bgcolor}
                                                onChange={(e)=> {
                                                    set_canvas(prev=>{return{...prev,bgcolor:(`${e.target.value}`)}});
                                                }}
                                                onBlur={()=>{
                                                    set_brush_history(prev=> {
                                                        let new_history = prev;
                                                        if (new_history.indexOf(canvas.bgcolor) <= -1) new_history.push(canvas.bgcolor);
                                                        return new_history;
                                                    })
                                                }}
                                            />
                                            </div>
                                            <div className="opacity-60 text-3xs">
                                            {canvas.bgcolor?.replace('#','')}
                                            </div>
                                        </div>  
                                    </div> */}
                                </div>

                            </div>


                        </div>

                        {/* BRUSH HISTORY */}
                        <div className="mt-5">
                            <div className='mb-1'>
                                Recent Colors
                            </div>
                            <div className='w-[72px]'>
                            
                            {brush_history.map((history,index)=> {
                                return (<div 
                                        key={index}
                                        className=" mr-[1px] mb-[1px] inline-block cursor-pointer"
                                        onClick={()=> {
                                            set_brush({...brush,color:(`${history}`)});
                                        }}
                                        >
                                            <div 
                                                
                                                className={`w-4 h-4 block border`}
                                                style={{background: history}}
                                                
                                            />
                                            
                                        </div>)
                            })}
                            </div>
                        </div>

                        {/* UNDO HISTORY */}
                        {/* <div className="mt-10 hidden">{cStep}

                        
                            <div className='w-32'>
                            {cPushArray.map((history,index)=> {
                                return (<div 
                                        key={index}
                                        
                                        
                                        >
                                            {history}
                                            
                                        </div>)
                            })}
                            </div>
                        </div> */}

                        <div className="mt-4">
                            <a
                                download={`${hasData(canvas.title) ? canvas.title : "Untitled image"}.png`}
                                href={cPushArray[cStep]?.replace('data:image/png;','data:application/octet-stream;')}
                                className={` w-full cursor-pointer font-bold rounded-md border text-center border-gray-700 hover:border-white  px-2 py-0.5`}
                            >
                                download
                            </a>
                        </div>
                        {cStep >= 0 && 
                        <div className="text-center w-full mt-1 text-3xs">
                        {`${formatBytes((canvas?.size > 0 && cStep == 1) 
                            ? canvas.size 
                            : getContentLength(cPushArray[cStep-1]),0)}`}
                        </div>
                        }

                        {save_behavior=="app" &&
                        <div
                            onClick={(e)=>submitHandler(e)}
                            className={`${show_tools ? 'mt-4' : ''} text-white text-lg bg-red-600 hover:bg-red-500 cursor-pointer font-bold rounded-md border text-center border-gray-700 hover:border-white  px-2 py-0.5`}
                        >
                            Save
                        </div>
                        }

                        <div
                            onClick={async (e)=> {
                                if (confirm('Are you sure you want to reset everything?')) {
                                    set_canvas(prev=>{return {...prev,
                                        bgcolor:'#ffffff',
                                        title:"Untitled image",
                                        src:"",
                                        id:"0",
                                        size:0
                                      }});
                                    await set_brush_history([]);
                                    await resetCanvas(canvasRef.current.getContext('2d'));
                                    await set_cPushArray([]);

                                }
                            }}
                            className={` w-full cursor-pointer font-bold rounded-md border text-center border-gray-700 hover:border-white  px-2 py-0.5 mt-2`}
                        >
                            Reset
                        </div>

                    </div>
                    }
                    
                   
                </div>
                
            </div>
            
        </div>
        
    </>);

}

export default Canvas;

