
import FormInput from "@/components/templateux/form-input/form-input";
import LoadingInner from "@/components/templateux/loading/loading-inner";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { CHAT_IMAGE_PERMS, config } from "@/lib/config";
import { allowImages } from "@/lib/utils/allowImages";
import { calculateAspectRatioFit } from "@/lib/utils/calculateAspectRatioFit";
import { hasData } from "@/lib/utils/hasData";
import { hasDataArrayElement } from "@/lib/utils/hasDataArrayElement";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { IsJsonValid } from "@/lib/utils/isJsonValid";
import { useEffect, useState } from "react";

function Uploads({ 
       //formref
       sendmsg
      ,closeModal
      ,bol_private
      ,channel_id
      ,set_clickval
      ,set_canvas
      ,profiledata
      ,index_json=null
   }) {

    // const [width, set_width] = useState(200);
    const [processing,set_processing]= useState(-1);
    const [upload_file,set_upload_file] = useState('[]')
    const [page,set_page] = useState(1)
    const [cache,set_cache] = useState(+ new Date())
    const [uploadarray_active,set_uploadarray_active] = useState([])

    // useEffect(()=> {
    //   if (typeof window !== 'undefined') {
    //     set_width(window.innerWidth);
    //   }
    // },[])
    

      //POST DATA
  const { 
    data: uploadarraydata
  , loading: uploadarraydataisLoading
  , error: uploadarraydataisError
  , boundMutate: itemsMutate
                  } = useSWRWrapper(
                    '1' //id
                    ,`/api/private/upload/select?page=${page}&cache=${cache}&type=1` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
      if (!uploadarraydataisLoading && !uploadarraydataisError && uploadarraydata) {
        set_uploadarray_active(uploadarraydata);
      }
    },[
      uploadarraydata
      ,uploadarraydataisLoading
      ,uploadarraydataisError
    ])

    const postinchat = (<>
    {(hasDataArrayElement(upload_file,0) && hasData(JSON.parse(upload_file)[0]?.url)) &&
      <div
        className=" flex justify-center w-full items-center content-center group "
        
      >
          <div
             onClick={(e) => {
              e.preventDefault();
              let upload_file_parsed = JSON.parse(upload_file)[0]
    
              //console.log(JSON.parse(upload_file)[0]?.url)
              let resize = calculateAspectRatioFit(upload_file_parsed?.width,upload_file_parsed?.height,400,200);
    
              sendmsg(e,channel_id,"message",{text:`![${upload_file_parsed?.alt}](${upload_file_parsed?.url} =${resize.width}x${resize.height} "${upload_file_parsed?.alt}")${hasData(upload_file_parsed?.caption) 
    ? `
${upload_file_parsed?.caption}` 
    : ''}`,emoji:3,bol_private:bol_private},upload_file_parsed);
              closeModal();
              }}
            className={`animate-pulse cursor-pointer bg-red-600 text-white p-4 px-8 font-bold text-xl rounded-full group-hover:bg-red-500  text-center flex-nowrap`}
          >
            Post Image in Chat
          </div>
      </div>
      }
    </>)

    //check to see if this is enabled, otherwise return an error message
    if (!allowImages(profiledata?.bol_chat_image,
      profiledata?.bol_chat_image_bitarray,
      "upload",
      CHAT_IMAGE_PERMS) ) {
      return (<>Sorry! Chat uploads have been disabled by {profiledata?.user_name}.</>)
    }

    return (<>
    <div className='flex flex-col w-full'>
      <div className='w-full flex-1'>

        <div className='border p-2 pb-4 border-gray-700 rounded-md mb-5'>
      
          <FormInput 
            type={'image'}
            title={`Upload an image damn it.`}
            description={''}
            helptip={''}
            name={'upload_file'}
            placeholder={''}
            options={[{
                      max_files:config.chat.uploads.max_files
                    }]}
            
            value={upload_file}
            set_value={set_upload_file}

            //submitting
            submitting={false}
            set_submitting={()=>{}}
            set_changes={()=>{}}
            changes={false}
            processing={processing}
            set_processing={set_processing}
            index={0}
          />

          
          {postinchat}
        </div>
      </div>
      <div
        className='mt font-bold text-lg mb-2 flex items-center content-center'
      >
       
        <div className="flex-1">
          Recent Uploads and Drawings
        </div>

        {uploadarraydataisLoading && 
        <LoadingInner
            showRandom={true}
            classSize='w-16 h-16 sm:w-24 sm:h-24'
        />
        }

        {!uploadarraydataisLoading && 
        <div className="flex-0 flex items-center content-center">
          {page>1 &&
          <div className="flex-0 cursor-pointer"
            onClick={()=>set_page(prev=>prev-1)}
          >
             <SvgJsx 
                  type={'fill'}
                  icon={"chevron-left-sm"}
                  className={`h-5 w-5  `}
                  title={"open menu"}
              />
          </div>
          }
          <div className="flex-0 cursor-pointer ml-2"
           onClick={()=>set_page(prev=>prev+1)}
          >
            <SvgJsx 
                  type={'fill'}
                  icon={"chevron-right-sm"}
                  className={`h-5 w-5  `}
                  title={"open menu"}
              />
          </div>
        </div>
        }
      </div>
      <div className="w-full flex-1 grid grid-cols-4 gap-2 items-bottom  content-end">
        {uploadarraydata?.filter(f=>["png","gif","jpg","jpeg","webp"]?.indexOf(f?.extention) > -1)?.map((u,index)=>{

          let resize = calculateAspectRatioFit(u.width,u.height,400,200);


          return (<div 
                    key={index}
                    className="group flex flex-col"
                  >
                    
                  <div
                    className="flex-0 "
                    onClick={(e) => {
                      e.preventDefault();
                      sendmsg(e,channel_id,"message",{text:`![${u?.filename}](${u?.url} =${resize.width}x${resize.height} "")`,emoji:3,bol_private:bol_private},u);
                      closeModal();
                      }}
                  >
                    <img
                      className="bg-white mx-auto cursor-pointer hover:opacity-80 border  border-gray-700 group-hover:border-white "
                     // src={u?.url?.replace(`.${u?.extention}`,`_sq.${u?.extention}`)} 
                      src={u?.url} 
                      alt={u?.filename}
                    />
                  </div>

                  <div className="flex items-center content-center justify-center mt-2 mb-5">
                      <div>
                        <div
                          className=" flex justify-center"
                          onClick={(e) => {
                            e.preventDefault();
                            sendmsg(e,channel_id,"message",{text:`![${u?.filename}](${u?.url} =${resize.width}x${resize.height} "")`,emoji:3,bol_private:bol_private},u);
                            closeModal();
                            }}
                        >
                          <div className="cursor-pointer border  border-gray-700 group-hover:border-white px-2 py-1 text-xs rounded-md">
                            post
                          </div>
                        </div>
                      </div>
                      <div className="ml-2">
                        <div
                          className=" flex justify-center"
                          onClick={() => {
                            set_clickval("draw",<></>,index_json);
                            set_canvas(prev=>{               

                              if (!IsJsonValid(u?.metadata_json)) {
                                return prev
                              } else {
                                
                        
                                let metadata_json = JSON.parse(u?.metadata_json)[0]
                        
                                return {...prev,
                                  width:u?.width,
                                  height:u?.height,
                                  bgcolor:metadata_json?.bgcolor || config.draw.bgcolor,
                                  brushcolor:config.draw.brushcolor,
                                  title:metadata_json?.title,
                                  src:u?.url, //metadata_json?.src,
                                  size:u?.size
                                }
                              }
                              
                          
                            });
                            // set_show_canvas(true);
                          }}
                        >
                          <div className="cursor-pointer border  border-gray-700 hover:border-white px-2 py-1 text-xs rounded-md">
                            edit
                          </div>
                        </div>
                      </div>
                  </div>

                </div>)
        })}
      </div>
    </div>
    </>);
  }


export default Uploads