import { hasData } from "@/lib/utils/hasData"
import parsehtml from "@/lib/utils/parsehtml"
import parseMarkDown from "@/lib/utils/parseMarkDown"
import Link from "next/link"
import ErrorBoundary from "./error-boundary"
import { useContext } from "react"
import { TribeContext } from "../context/tribe-wrapper"



function MarkdownPreview({
    preview_content
}) {
    return <>
        <div className="my-2  flex items-center content-center">
          <div className="flex-0 mr-2 text-xl font-bold">
            Preview
          </div> 
          <div className="flex-0 ">
            <Link
              href="https://www.markdownguide.org/cheat-sheet/"
              className="text-blue-500 underline hover:no-underline"
              rel="noreferrer"
              target="_blank">
              
                Markdown Guide
              
            </Link>
          </div>
        </div>
        <MarkdownContent
          preview_content={preview_content}
        />
        </>;
}

export function MarkdownContent({
  preview_content
}) {

  const ssr_data_tribe = useContext(TribeContext)

  return (<>
  <ErrorBoundary>
    <div className="markdown">
      {hasData(preview_content) 
      ? parsehtml(parseMarkDown(preview_content,ssr_data_tribe?.server_website))
      : 'Nothing to see here folks'
      } 
      </div>  
    </ErrorBoundary>
  </>)
}

export default MarkdownPreview