
import { hasData } from '@/lib/utils/hasData';
import { hasDataArrayElement } from '@/lib/utils/hasDataArrayElement';
import { useEffect, useState } from 'react';
import Canvas from '@/components/draw/Canvas';
import { allowImages } from '@/lib/utils/allowImages';
import { CHAT_IMAGE_PERMS } from '@/lib/config';

function ChatDraw({
   router
  ,sendmsg
  ,closeModal
  ,bol_private
  ,channel_id
  ,canvas
  ,set_canvas
  ,bol_tools=true
  ,profiledata
  ,changes
  ,set_changes
}) {

  const [upload_file,set_upload_file] = useState('[]')

  const [submitting,set_submitting] = useState(false)

  useEffect(()=>{
    //const abortController = new AbortController();
    if (typeof window !== undefined) {
      set_canvas(prev=>{return{...prev,
        width: Math.min(parseInt(prev.width),(window.innerWidth-200)).toString(),
        height: Math.min(parseInt(prev.height),(window.innerHeight-300)).toString(),
      }})
    }
    return () => {
      //abortController.abort(); 
    }
  },[])

  const onMessage = async () => {
    await sendmsg(null,
      channel_id,
      "message",
      {text:`![${JSON.parse(upload_file)[0]?.alt}](${JSON.parse(upload_file)[0]?.url} =300x200 "")${hasData(JSON.parse(upload_file)[0]?.caption) 
        ? `\n`+
          `${JSON.parse(upload_file)[0]?.caption}` 
        : ''}`,
        emoji:4,
        bol_private:bol_private
      },
      JSON.parse(upload_file)[0]);
      await set_submitting(false);
      set_changes(false);
      await closeModal(true); //override the error message
  }

  useEffect(()=> {
    if (hasDataArrayElement(upload_file,0) && hasData(JSON.parse(upload_file)[0]?.url)) {
      setTimeout(()=>onMessage(),1000);
    }
  },
  [
    upload_file
  ])

  //check to see if this is enabled, otherwise return an error message
  if (!allowImages(profiledata?.bol_chat_image,
    profiledata?.bol_chat_image_bitarray,
    "draw",
    CHAT_IMAGE_PERMS) ) {
    return (<>Sorry! Chat drawings have been disabled by {profiledata?.user_name}.</>)
  }

  return (
    <div className="ignore-scroll"> 

      <Canvas
        canvas={canvas}
        set_canvas={set_canvas}
        save_behavior={'local'}
        set_upload_file={set_upload_file}
        router={router}
        submitting={submitting}
        set_submitting={set_submitting}
        bol_tools={bol_tools}
        changes={changes}
        set_changes={set_changes}
      />

    </div>
  );
}

export default ChatDraw;